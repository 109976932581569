<template>
    <v-container fluid>
        <v-row class="mb-1" no-gutters>
            <v-col>
                <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode }">{{ $store.getters.getTextMap().production_planner }}</h2>
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn 
                    @click="goBack"
                    :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined
                    class="mr-2"  
                >
                <v-icon left :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-arrow-left-bold-outline</v-icon>
                 {{ $store.getters.getTextMap().back }}
                </v-btn>
                <AddMachineProductionPlanModal v-on:update="refresh=true"/> 
            </v-col>
        </v-row>
        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
        <v-row no-gutters>
            <v-col>
                <MachineProductionPlanPanel :refresh="refresh"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import AddMachineProductionPlanModal from "@/components/modals/oee/AddMachineProductionPlanModal";
import MachineProductionPlanPanel from "@/components/panels/oee/MachineProductionPlanPanel.vue";
    export default {
        name:"MachineProductionPlan",
        components: {
            AddMachineProductionPlanModal,
            MachineProductionPlanPanel
        },
        data() {
            return {
                refresh:false
            }
    },
        methods: {
            goBack() {
                this.$router.go(-1);
            }
        }
    }
</script>