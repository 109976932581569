<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row v-if="tableData && tableData.length>0">
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="tableData"
                    item-key="index"
                    :search="search"
                    :single-expand="true"
                    show-expand
                    :expanded.sync="expanded"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :dark="$store.getters.getColorPalette().isDark"
                >

                    <!-- Expanded Item Slot -->
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <AddSKUToMachineProductionPlan :planDetails="item" v-on:update="getData"/>
                            <V-divider/>
                            <MachineProductionPlanDetailsTable :planDetails="item" v-on:update="getData" />
                        </td>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                 <div :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                    <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import axios from 'axios';
import moment from 'moment';
import MachineProductionPlanDetailsTable from './MachineProductionPlanDetailsTable.vue';
import AddSKUToMachineProductionPlan from '@/components/crud_components/oee/AddSKUToMachineProductionPlan.vue';
export default {
    name: 'MachineProductionPlanTable',
    props: ["shift", "machines", "from_date", "to_date","refresh"],
    components: {
        InfoAlert,
        MachineProductionPlanDetailsTable,
        AddSKUToMachineProductionPlan
    },
    data() {
        return {
            showDismissibleAlert: false,
            loading: false,
            info: '',
            tableData: [],
            expanded: [],
            search:'',
            headers: [
              {
                text: 'Date',
                align: 'start',
                filterable: false,
                value: 'formatted_date',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
              },
              { text: 'Shift', value: 'shift_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Machine', value: 'machine_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'From Time', value: 'from_time', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Production Time', value: 'production_time', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Ideal Cycle Time', value: 'ideal_cycle_time', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName }
            ],
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData() {
            this.loading = true;
            let payload = {
                machines: this.machines,
                shift_id: this.shift,
                from_date: this.from_date,
                to_date:this.to_date
            }
            axios.post(this.$store.state.api + "getProductionPlanBetweenDate",payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                let dbData = response.data.data
                let temp = []
                let index = 0
                for (let i of dbData) {
                    let machineDetails = this.$store.getters.getMachineById(i.machine_id)
                    let shiftDetails = this.$store.getters.getShiftById(i.shift_id)
                    temp.push(
                        {
                            index,
                            shift_id: i.shift_id,
                            machine_id:i.machine_id,
                            shift_name:shiftDetails && shiftDetails.name?shiftDetails.name: i.shift_id,
                            machine_name:machineDetails && machineDetails.name?machineDetails.name:i.machine_id,
                            date: i.date,
                            from_time:i.from_time,
                            formatted_date:moment(i.date).format('DD-MM-YYYY'),
                            production_time:i.production_time,
                            ideal_cycle_time:i.ideal_cycle_time
                        }
                    )
                    index=index+1
                }
                this.tableData=temp
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        }
    },
    watch: {
        shift() {
            this.getData();
        },
        machines() {
            this.getData();
        },
        from_date() {
            this.getData();
        },
        to_date() {
            this.getData();
        },
        refresh() {
            this.getData()
        }
    }
}
</script>