<template>
    <div class="text-center">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-btn 
            @click="dialog = !dialog"
            :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined
            class="mr-2"  
        >
            {{ $store.getters.getTextMap().add_production_plans }}
        </v-btn>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().background2ColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ $store.getters.getTextMap().production_planner }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">
                            {{ $store.getters.getTextMap().close }}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <AddMachineProductionPlan v-on:update="$emit('update')"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import axios from 'axios';
import moment from "moment";
import AddMachineProductionPlan from '@/components/crud_components/oee/AddMachineProductionPlan.vue';
export default {
    name: 'AddMachineProductionPlanModal',
    components: {
        InfoAlert,
        AddMachineProductionPlan
    },
    data() {
        return {
            dialog: false,
            showDismissibleAlert:false,
            loading:false,
            info: '',
            shiftOptions: [],
            machineOptions:[],
            shift: null,
            machines: [],
            menu1:false,
            menu2: false,
            to_date:null,
            from_date: null,
            tableData: [],
            expanded: [],
            search:'',
            headers: [
              {
                text: 'Date',
                align: 'start',
                filterable: false,
                value: 'formatted_date',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
              },
              { text: 'Shift', value: 'shift_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Machine', value: 'machine_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'From Time', value: 'from_time', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Down Time From', value: 'downtime_from', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Downtime Duration', value: 'downtime_duration', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Downtime Type', value: 'downtime_type', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Downtime Reason', value: 'downtime_reason', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Remarks', value: 'remarks', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Details', value: 'details', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'User ID', value: 'userid', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
            ],
            objectRules: [
                v => !!v || this.$store.getters.getTextMap().required,
            ],
            ISODateRules:[
                v => !!v ||this.$store.getters.getTextMap().required,
                v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
            ]
        }
    },
    mounted() {
        this.from_date=moment().startOf('month').format('YYYY-MM-DD')
        this.getShifts()
    },
    methods: {
        getShifts(){
            let op=[]
            this.loading = true;
            axios.get(this.$store.state.api + "shifts",{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                let shifts=response.data.data
                for(let i of shifts){
                    op.push({value:i.shift_id, label: i.name })
                }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
          this.shiftOptions = op
        },
        getMachines() {
            this.loading=true
            let op = []
            axios.post(this.$store.state.api+'getMachinesByShift',{shift_id:this.shift},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if (response.data.status == 'success') {
                    let machines = response.data.data
                    for(let i of machines){
                        if(i && i.name){
                            op.push({value:i.machine_id, label: i.name })
                        }else{
                            op.push({value:i.machine_id, label: i.machine_id })
                        }
                    }
                    this.loading=false
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
            this.machineOptions=op
        },
        getData() {
            this.loading = true;
            let payload = {
                machines: this.machines,
                shift_id: this.shift,
                from_date: this.from_date,
                to_date:this.to_date
            }
            axios.post(this.$store.state.api + "getShiftAndMachineWiseDowntimeBetweenDate",payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                let dbData = response.data.data
                let temp = []
                let index = 0
                for (let i of dbData) {
                    let machineDetails = this.$store.getters.getMachineById(i.machine_id)
                    let shiftDetails = this.$store.getters.getShiftById(i.shift_id)
                    temp.push(
                        {
                            index,
                            shift_id: i.shift_id,
                            machine_id:i.machine_id,
                            shift_name:shiftDetails && shiftDetails.name?shiftDetails.name: i.shift_id,
                            machine_name:machineDetails && machineDetails.name?machineDetails.name:i.machine_id,
                            date:i.date,
                            formatted_date:moment(i.date).format("DD-MM-YYYY"),
                            from_time: i.from_time,
                            downtime_from: moment(i.downtime_from).format("DD-MM-YYYY: HH-MM"),
                            downtime_from_epoch:i.downtime_from_epoch,
                            downtime_duration: i.downtime_duration,
                            downtime_type: i.downtime_type,
                            downtime_reason: i.downtime_reason,
                            remarks: i.remarks,
                            details: i.details,
                            userid:i.userid
                        }
                    )
                    index=index+1
                }
                this.tableData=temp
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        }
    },
    watch: {
        shift() {
            this.machines=[]
            this.getMachines()
        },
        machines() {
            if (this.shift && this.from_date && this.machines && this.machines.length>0) {
                this.getData()
            }
        },
        to_date() {
            if (this.shift && this.from_date && this.machines && this.machines.length>0) {
                this.getData()
            }
        },
        from_date() {
            if (this.shift && this.from_date && this.machines && this.machines.length>0) {
                this.getData()
            }
        }
    }
}
</script>
