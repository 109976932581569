<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-row>
            <v-col cols="auto">
                <v-icon :color="$store.getters.getColorPalette().accentCode">mdi-filter</v-icon>
            </v-col>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    v-if="!loading"
                    v-model="timeframe"
                    :items="timeframeOptions"
                    :label="$store.getters.getTextMap().timeFrame"
                    dense
                    outlined
                    item-text="label"
                    item-value="value"   
                ></v-select>  
            </v-col>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-select  
                    v-if="!loading"
                    v-model="shift"
                    :items="shiftOptions"
                    :label="$store.getters.getTextMap().shift"
                    dense
                    outlined
                    item-text="label"
                    item-value="value"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :dark="$store.getters.getColorPalette().isDark"
                ></v-select  >
            </v-col>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-select  
                    v-if="!loading"
                    v-model="machines"
                    :items="machineOptions"
                    :label="$store.getters.getTextMap().machine"
                    dense
                    outlined
                    item-text="label"
                    item-value="value"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    multiple
                    :dark="$store.getters.getColorPalette().isDark"
                ></v-select  >
            </v-col>
        </v-row>
        <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
        <v-row no-gutters v-if="from_date">
            <v-col>
                <MachineProductionPlanTable :shift="shift" :machines="machines" :from_date="from_date" :to_date="to_date" :refresh="refresh"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import axios from 'axios';
import moment from 'moment';
import MachineProductionPlanTable from '@/components/lists/oee/MachineProductionPlanTable.vue';
export default {
    name: 'MachineProductionPlanPanel',
    props:["refresh"],
    components: {
        MachineProductionPlanTable,
        InfoAlert
    },
    data() {
        return {
            showDismissibleAlert: false,
            loading:false,
            info: '',
            shiftOptions: [],
            machineOptions: [],
            shift: null,
            machines: [],
            timeframe: '30',
            from_date: null,
            to_date:null,
            timeframeOptions: [
                {label:'Next 7 days', value:'7'},
                {label: 'Next 30 days', value: '30'},
                {label:'Next 60 days', value:'60'},
                {label:'Next 90 days', value:'90'},
                {label:'Next 180 days', value:'180'},
                {label:'Next 365 days', value:'365'},
                // {label:'MTD', value:'mtd'},
                // {label:'YTD', value:'ytd'},
            ],
            objectRules: [
                v => !!v || this.$store.getters.getTextMap().required,
            ],
        }
    },
    mounted() {
        this.getFromAndToDate()
        this.getShifts()
    },
    methods: {
        getShifts(){
            let op=[]
            this.loading = true;
            axios.get(this.$store.state.api + "shifts",{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                let shifts=response.data.data
                for(let i of shifts){
                    op.push({value:i.shift_id, label: i.name })
                }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
          this.shiftOptions = op
        },
        getMachines() {
            this.loading=true
            let op = []
            axios.post(this.$store.state.api+'getMachinesByShift',{shift_id:this.shift},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if (response.data.status == 'success') {
                    let machines = response.data.data
                    for(let i of machines){
                        if(i && i.name){
                            op.push({value:i.machine_id, label: i.name })
                        }else{
                            op.push({value:i.machine_id, label: i.machine_id })
                        }
                    }
                    this.loading=false
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
            this.machineOptions=op
        },
        getFromAndToDate() {
            this.from_date=moment().format('YYYYMMDD');
            switch (this.timeframe) {
                case '7':
                    this.to_date = moment().add(7,'days').format('YYYYMMDD');
                    break;
                case '30':
                    this.to_date = moment().add(30,'days').format('YYYYMMDD');
                    break;
                case '60':
                    this.to_date = moment().add(60,'days').format('YYYYMMDD');
                    break;
                case '90':
                    this.to_date = moment().add(90,'days').format('YYYYMMDD');
                    break;
                case '180':
                    this.to_date = moment().add(180,'days').format('YYYYMMDD');
                    break;
                case '365':
                    this.to_date = moment().add(365,'days').format('YYYYMMDD');
                    break;
                default:
                    this.to_date = moment().add(30,'days').format('YYYYMMDD');
                    break;
          }
        }
    },
    watch: {
        shift() {
            this.machines=[]
            this.getMachines()
        },
        timeframe() {
            this.getFromAndToDate()
        }
    }
}
</script>