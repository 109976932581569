<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row v-if="tableData && tableData.length>0">
            <v-col>
                <v-simple-table :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}" >
                    <template v-slot:default>
                        <thead :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                            <tr>
                                <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().sku}}</th>
                                <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().name}}</th>
                                <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().quantity}}</th>
                                <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().action}}</th>
                            </tr>
                        </thead>
                        <tbody :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                            <tr v-for="(item,ind) in tableData"  :key="ind" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <td  class="text-center">{{ item.sku_id }}</td>
                                <td  class="text-center">{{ item.sku_name }}</td>
                                <td  class="text-center">{{ item.qty }}</td>
                                <td class="text-center">
                                    <DeleteConfirmation  v-on:confirm="deleteProductionPlanForGivenSKU(item)" title="Delete Confirmation"  description="Are you sure you want to delete this gauge?">
                                        <v-icon small color="red">mdi-delete</v-icon>
                                    </DeleteConfirmation>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                 <div :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                    <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import axios from 'axios';
import moment from 'moment';
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name: 'MachineProductionPlanDetailsTable',
    props: ["planDetails"],
    components: {
        InfoAlert,
        DeleteConfirmation
    },
    data() {
        return {
            showDismissibleAlert: false,
            loading: false,
            info: '',
            tableData: [],
            expanded: [],
            search:'',
            headers: [
              {
                text: 'Date',
                align: 'start',
                filterable: false,
                value: 'formatted_date',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
              },
              { text: 'Shift', value: 'shift_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Machine', value: 'machine_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'From Time', value: 'from_time', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Production Time', value: 'production_time', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
            ],
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.planDetails.machine_id && this.planDetails.shift_id && this.planDetails.from_time && this.planDetails.date) {
                this.getData()
            }  
        },
        getData() {
            this.loading = true;
            let payload = {
                machine_id: this.planDetails.machine_id,
                shift_id: this.planDetails.shift_id,
                from_time: this.planDetails.from_time,
                date:moment(this.planDetails.date).format("YYYY-MM-DD")
            }
            axios.post(this.$store.state.api + "getMachineProductionPlanDetails",payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
                if (response.data.status == "success") {
                    let dbData = response.data.data
                    let temp = []
                    for (let i of dbData) {
                        let skuDetails = this.$store.getters.getSKUById(i.sku_id)
                        temp.push({
                            "sku_id":i.sku_id,
                            "sku_name": skuDetails && skuDetails.sku_name ? skuDetails.sku_name : i.sku_id,
                            "qty":i.qty
                        })
                    }
                this.tableData = temp
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        },
        deleteProductionPlanForGivenSKU(item) {
            this.loading = true
            let payload = {
                sku_id: item.sku_id,
                machine_id: this.planDetails.machine_id,
                shift_id: this.planDetails.shift_id,
                from_time: this.planDetails.from_time,
                date:moment(this.planDetails.date).format("YYYY-MM-DD")
            }
            axios.post(this.$store.state.api + "deleteMachineProductionPlanBySKU",payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
                if (response.data.status == "success") {
                    this.getData()
                    this.info = `Production plan for ${item.sku_id} deleted successfully!`;
                    this.showDismissibleAlert = true;
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                    this.loading = false;
                })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        }
    },
    watch: {
        planDetails() {
            this.init()
        }
    }
}
</script>