<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-form ref="productionPlannerForm" v-model="valid">
            <v-row>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        v-if="!loading"
                        v-model="shift"
                        :items="shiftOptions"
                        :label="$store.getters.getTextMap().shift"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                        :rules="[rules.required]"
                    ></v-select  >
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        v-if="!loading"
                        v-model="form.from_time"
                        :items="shiftDataOptions"
                        :label="$store.getters.getTextMap().from"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                        :rules="[rules.required]"
                    ></v-select  >
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        v-if="!loading"
                        v-model="form.machine"
                        :items="machineOptions"
                        :label="$store.getters.getTextMap().machine"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                        :rules="[rules.required]"
                    ></v-select  >
                </v-col>
                <v-col>
                    <v-menu 
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-model="form.date"
                                :label="$store.getters.getTextMap().date"
                                prepend-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                :loading="loading"
                                v-bind="attrs"
                                v-on="on"
                                :rules="ISODateRules"
                            ></v-text-field>
                        </template>
                        <v-date-picker 
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-model="form.date"
                            @input="menu1 = false"
                        ></v-date-picker>  
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.production_time"
                        :counter="3"
                        :label="`${$store.getters.getTextMap().production_time} (${$store.getters.getTextMap().minutes})`"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.ideal_cycle_time"
                        :counter="3"
                        :label="`${$store.getters.getTextMap().ideal_cycle_time} (${$store.getters.getTextMap().minutes})`"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
                <!-- <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        v-if="!loading"
                        v-model="form.sku_id"
                        :items="skus"
                        :label="$store.getters.getTextMap().sku"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                        :rules="[rules.required]"
                    ></v-select  >
                </v-col>
                <v-col>
                     <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.qty"
                        :counter="3"
                        :label="$store.getters.getTextMap().quantity" 
                        dense
                        outlined
                    ></v-text-field>
                </v-col> -->
            </v-row>
             <v-row no-gutters>
                <v-col class="d-flex justify-end">
                    <v-btn v-if="!loading" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import axios from 'axios';
import moment from 'moment';
import FormRules from '@/utillities/FormRules'
export default {
    name: 'AddMachineProductionPlan',
    components: {
        InfoAlert
    },
    data() {
        return {
            showDismissibleAlert:false,
            loading:false,
            info: '',
            menu1:false,
            valid:false,
            shiftOptions: [],
            shiftDataOptions:[],
            machineOptions: [],
            shift: null,
            form: {
                from_time: null,
                // sku_id:null,
                machine: null,
                production_time: 0,
                ideal_cycle_time:0,
                date:moment().format('YYYY-MM-DD'),
                // qty:0,
            },
            rules:FormRules.rules,
            ISODateRules:FormRules.ISODateRules
        }
    },
    mounted() {
        this.getShifts()
    },
    computed:{
        skus(){
            let skus=[]
            if (this.$store.state.skus) {
                for (let i of this.$store.state.skus) {
                    skus.push({value:i.sku_id,label:i.sku_name})
                }
            }
            return skus
        },
    },
    methods: {
        getShifts(){
            let op=[]
            this.loading = true;
            axios.get(this.$store.state.api + "shifts",{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                let shifts=response.data.data
                for(let i of shifts){
                    op.push({value:i.shift_id, label: i.name })
                }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
          this.shiftOptions = op
        },
        getShiftData() {
            this.loading=true
            let op=[]
            let response = this.$store.getters.getShiftDataById(this.shift)
            for (let i of response) {
                op.push({value:i.from_time,label:i.name+` (${i.from_time} - ${i.to_time})`})
            }
            this.shiftDataOptions = op
            this.loading=false
        },
        getMachines() {
            this.loading=true
            let op = []
            axios.post(this.$store.state.api+'getMachinesByShift',{shift_id:this.shift},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if (response.data.status == 'success') {
                    let machines = response.data.data
                    for(let i of machines){
                        if(i && i.name){
                            op.push({value:i.machine_id, label: i.name })
                        }else{
                            op.push({value:i.machine_id, label: i.machine_id })
                        }
                    }
                    this.loading=false
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
            this.machineOptions=op
        },
        onSubmit() {
            if (this.$refs.productionPlannerForm.validate()) {
                let payload = Object.assign({}, this.form)
                payload["shift_id"] = this.shift
                axios.post(this.$store.state.api + "addMachineProductionPlan",payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        this.$emit("update")
                        this.info = "Production plan added successfully.";
                        this.showDismissibleAlert = true;
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
        }
    },
    watch: {
        shift() {
            this.form.machine = null
            this.form.from_time=null
            this.getShiftData()
            this.getMachines()
        }
    }
}
</script>