<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="SKUProductionPlannerForm" v-model="valid">
            <v-row>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        v-if="!loading"
                        v-model="form.sku_id"
                        :items="skus"
                        :label="$store.getters.getTextMap().sku"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                        :rules="[rules.required]"
                    ></v-select  >
                </v-col>
                <v-col>
                     <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.qty"
                        :counter="3"
                        :label="$store.getters.getTextMap().quantity" 
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>
             <v-row no-gutters>
                <v-col class="d-flex justify-end">
                    <v-btn v-if="!loading" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import axios from 'axios';
import moment from 'moment';
import FormRules from '@/utillities/FormRules'
export default {
    name: 'AddSKUToMachineProductionPlan',
    props:["planDetails"],
    components: {
        InfoAlert
    },
    data() {
        return {
            showDismissibleAlert:false,
            loading:false,
            info: '',
            valid: false,
            form: {
                sku_id:null,
                qty:0,
            },
            rules:FormRules.rules,
        }
    },
    mounted() {
        if (this.planDetails && this.planDetails.shift_id && this.planDetails.machine_id && this.planDetails.from_time && this.planDetails.date) { 
            this.form["shift_id"]=this.planDetails.shift_id
            this.form["machine_id"]=this.planDetails.machine_id
            this.form["from_time"]=this.planDetails.from_time
            this.form["date"] = moment(this.planDetails.date).format("YYYY-MM-DD")
        } else {
            this.info = "Production plan is required for adding SKU";
            this.showDismissibleAlert = true;
        }
    },
    computed:{
        skus(){
            let skus=[]
            if (this.$store.state.skus) {
                for (let i of this.$store.state.skus) {
                    skus.push({value:i.sku_id,label:i.sku_name})
                }
            }
            return skus
        },
    },
    methods: {
        onSubmit() {
            if (this.$refs.SKUProductionPlannerForm.validate()) {
                let payload = Object.assign({}, this.form)
                axios.post(this.$store.state.api + "addSKUToMAchineProductionPlan",payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        this.$emit("update")
                        this.info = "SKU added successfully.";
                        this.showDismissibleAlert = true;
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
        }
    }
}
</script>